




































































































































































































































































































































import DialogMixins from '@/mixins/DialogMixins';
import WidgetMixins from '@/mixins/WidgetMixins';
import { loadWidget } from '@/utils/helpers';
import Component, { mixins } from 'vue-class-component';
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import { required, max } from 'vee-validate/dist/rules';
import { Watch } from 'vue-property-decorator';
import { Getter, namespace } from 'vuex-class';
import { debounce } from 'lodash';
import {
  IContact,
  IGroup,
  IMessage,
  ISenderID,
  ISubAccount,
  ISubAccountDetails,
  IUser,
} from '@/types/types';

const groupModule = namespace('group');
const contactModule = namespace('contact');
const usersModule = namespace('users');
const smsModule = namespace('sms');

extend('required', {
  ...required,
  message: `Field is required`,
});
extend('max', {
  ...max,
  message: `Field cannot have more than 11 characters`,
});

@Component({
  name: 'QuickSMS',
  components: {
    InternetConnection: loadWidget('pages/InternetConnection'),
    ToolBar: loadWidget('widgets/ToolBar'),
    SnackBar: loadWidget('widgets/SnackBar'),
    EmptyPage: loadWidget('pages/EmptyPage'),
    ProgressBar: loadWidget('widgets/CircularProgressLoader'),
    BulkUploadDialog: loadWidget('widgets/BulkUpload'),
    AddSMSSenderID: loadWidget(`widgets/AddSenderID`),
    ValidationProvider,
    ValidationObserver,
  },
})
export default class QuickSMS extends mixins(WidgetMixins, DialogMixins) {
  @Getter('getCreditBalance') creditBalance!: number;
  @Getter('getUseIntlRoute') useIntlRoute!: boolean;
  @groupModule.Getter('getAllGroups') groups!: IGroup[];
  @Getter('getResetFormState') resetFormState!: boolean;
  @contactModule.Getter('getAllContacts') contacts!: IContact[];
  @smsModule.Getter('getSenderIDList') smsIDList!: Array<
    ISenderID & { uuid: string; id: number; slug: string }
  >;
  @usersModule.Getter('getSearchResults') subAccounts!: IUser[];
  isSubAccountSelected = false;
  smsType: string[] = ['Quick', 'Flash'];
  isContactsLoading = false;
  hint = '';
  totalCredit = 0;
  subAccount: ISubAccount = {
    payload: null,
    id: null,
  };

  @Watch('subAccount.payload', { deep: true })
  onSubAccountPayloadChange(payload: ISubAccountDetails): void {
    if (!payload) {
      this.isSubAccountSelected = false;
      this.subAccount.id = null;
      this.$store.dispatch('checkCreditBalance', null, { root: true });
      return;
    }
    this.isSubAccountSelected = true;
    this.subAccount.id = payload.id;
    this.$store.dispatch('updateCreditBalance', payload, {
      root: true,
    });
  }

  @Watch('contacts')
  onContactChange(payload: IContact[]): void {
    this.isContactsLoading = true;
    setTimeout(() => {
      this.sms.to = payload.map((contact) => contact.phone).join(',');
      this.isContactsLoading = false;
    }, 2000);
  }

  sms: IMessage = {
    to: '',
    from: '',
    type: 'Quick',
    message: '',
  };

  bulkUpload(file: File): void {
    let formData = new FormData();
    formData.append('file', file, file.name);
    this.$store.dispatch('contact/processContactsFile', formData);
  }

  @Watch('resetFormState')
  onFormChange(payload: boolean): void {
    if (payload) {
      this.sms = {
        to: '',
        message: '',
        from: this.sms.from,
        type: this.sms.type,
      };
    } else this.$store.dispatch('resetFormValues', false, { root: true });
  }

  @Watch('sms.to')
  onDestinationContentChange(phoneNumbers: string): void {
    this.totalCredit =
      Math.ceil(this.sms.message.length / 160) *
      (phoneNumbers?.split(',').length ?? 1);
    this.hint = `${this.sms.message?.length} words / ${this.totalCredit} sms unit`;
  }

  @Watch('sms.message')
  onMessageContentChange(content: string): void {
    this.totalCredit =
      Math.ceil(content.length / 160) * (this.sms.to?.split(',').length ?? 1);
    this.hint = `${content.length} words / ${this.totalCredit} sms unit`;
  }

  handleOnSenderIDListRefresh = debounce(() => {
    this.$store.dispatch('sms/listSenderID');
  }, 500);

  refreshSenderIDList(): void {
    this.handleOnSenderIDListRefresh();
  }

  sendSMS(): void {
    const payload = {
      body: {
        messages: this.sms.to?.split(',')?.map((phone) => {
          return {
            to: phone,
            message: this.sms.message,
            from: this.sms.from,
            type: this.sms.type,
          };
        }),
      },
      ...(this.subAccount.id && {
        subAccountId: {
          'x-subaccount-id': this.subAccount.id,
        },
      }),
    };
    this.$store.dispatch('sms/sendBulkSMS', payload);
    setTimeout(() => {
      this.subAccount = {
        payload: null,
        id: null,
      };
    }, 2000);
  }

  created(): void {
    this.$store.dispatch('group/rawList');
    this.$store.dispatch('users/rawList');
    this.$store.dispatch('checkCreditBalance');
    this.$store.dispatch('sms/listSenderID');
  }
}
